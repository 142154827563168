export const reviewPage = (function(){
    let my = {},
    ignoreScrollEvent = false;

    function _stringToHTML(string){
        let parser = new DOMParser();
        let doc = parser.parseFromString(string, 'text/html');
        return doc.body.firstChild;
    }

    my.init = function(){
        if(document.querySelector('#reviews_bottom')){
            window.addEventListener('scroll', function(){
                if (ignoreScrollEvent) {
                    return false;
                }
                
                let footer_height = document.querySelector('footer').offsetHeight;
                if ((window.innerHeight + window.window.pageYOffset) >= document.body.scrollHeight - footer_height){	
                    let reviews_parent = document.getElementById('reviews_bottom');
                    let pages = reviews_parent.dataset.pages;
                    let last_page = Number(reviews_parent.getAttribute('data-last-page'));
                    let rfac = reviews_parent.dataset.rfac;
                    if (pages > last_page){
                        last_page++;
                        reviews_parent.appendChild(_stringToHTML('<div id="review_listing_page_'+last_page+'" class="review_listing"></div>'));
                        reviews_parent.setAttribute('data-last-page', last_page);
                        ignoreScrollEvent = true;
                        let url = null;
                        if(rfac !== ''){
                            url = '/reviews-ajax/'+rfac+'?limit=10&page='+last_page;
                        }else{
                            url = '/reviews-ajax?limit=10&page='+last_page;
                        }

                        let response = fetch(url, {
                            method: 'get',
                            headers: {
                                "X-Requested-With": 'xmlhttprequest'
                            }
                        })
                        .then(function(response){
                            return response.text();
                        })
                        .then(function(text){
                            var list_page = document.getElementById('review_listing_page_'+last_page);
                            list_page.innerHTML = text;
                            list_page.classList.add('display');
                            setTimeout(function(){
                                ignoreScrollEvent = false;
                            }, 1000);
                        })
                        .catch(function(ex){
                            console.log('Error:', ex);
                        });
                    }
                }
            });
        }
    }

    return my;
})();