import {
	header
} from './header';
import {
	maps
} from './maps';
import {
	review_caro,
	feedback
} from './reviews';
import {
	reviewPage
} from './reviews_page';
import {
	contactSubmit
} from './contact.js';
import {
	supplies
} from './supplies.js';
import {
	features,
	caro
} from './index.js';
import {
	facilityCaro,
	facilityGallery,
	facilityMap,
	ratesShowMore,
	rateFilters,
	sidebar,
	facilityContentToggle
} from './facility.js';

export const template_code = (function () {
	let my = {};

	my.init = function () {
		header.init();
		maps.init();
		review_caro.init();
		feedback.init();
		reviewPage.init();
		features.init();
		supplies.init();

		facilityCaro.init();
		facilityGallery.init();
		facilityMap.init();
		rateFilters.init();
		ratesShowMore.init();
		sidebar.init();
		facilityContentToggle.init();

		if (document.getElementById('contact_form')) {
			contactSubmit();
		}
		caro.init();
	}

	return my;
})();