import {
    AutomatitCarousel
} from './automatit_carousel.m';

export const features = (function () {
    let my = {},
        moreFeatures = document.querySelector('#index_more_features .index_more_features_collapse'),
        toggle = document.querySelector('#index_more_features .toggle');

    function _toggleMoreFeatures() {
        if (!moreFeatures.classList.contains('active')) {
            moreFeatures.classList.add('active');
            moreFeatures.style.height = 'auto';

            let height = moreFeatures.clientHeight + 'px';

            moreFeatures.style.height = "0px";

            setTimeout(() => {
                moreFeatures.style.height = height;
            }, 0);
        } else {
            moreFeatures.style.height = "0px";

            moreFeatures.addEventListener('transitionend', () => {
                moreFeatures.classList.remove('active');
            }, {
                once: true
            });
        }
    }

    my.init = function () {
        if (toggle) {
            toggle.addEventListener('click', _toggleMoreFeatures);
        }
    }

    return my;
})();

export const caro = (function () {
    let my = {};

    my.init = function () {
        if (document.getElementById('index_carousel')) {
            AutomatitCarousel({
                element: document.getElementById('index_carousel'),
                imagesAsBackgrounds: true,
                images: [{
                        src: "/dist/images/home_slider1.jpg",
                        alt: ""
                    },
                    {
                        src: "/dist/images/home_slider2.jpg",
                        alt: ""
                    },
                    {
                        src: "/dist/images/home_slider3.jpg",
                        alt: ""
                    },
                    {
                        src: "/dist/images/home_slider4.jpg",
                        alt: ""
                    },

                ],
                useChevrons: false,
                showDots: true
            });
        }
    };

    return my;
})();