export const header = (function () {
	let my = {};

	function _toggleSubNav(e) {
		e.currentTarget.querySelector('.sub_nav').classList.toggle('open');
	}

	function _toggleMobile_menu(e) {
		e.currentTarget.classList.toggle('open');

		document.getElementById('site_nav').classList.toggle('open');
	}

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _assignListeners() {
		[].forEach.call(document.querySelectorAll('#site_nav .nav_parent'), (el) => {
			el.addEventListener('click', _toggleSubNav);
		});

		document.querySelector('#site_header_top .header_menu_toggle').addEventListener('click', _toggleMobile_menu);
	}

	my.init = function () {
		_assignListeners();
		_addDoubleClick();
	}

	return my;
})();