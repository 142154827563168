import {
    AutomatitCarousel
} from './automatit_carousel.m';
import {
    getMapsHelper
} from './util/mapsHelper';


export const facilityCaro = (function () {
    let my = {};

    my.init = function () {
        if (document.getElementById('facility_carousel')) {

            if (window.slide_images.length > 1) {
                const options = {
                    element: document.getElementById('facility_carousel'),
                    speed: 600,
                    images: window.small_slide_images,
                    intervalTiming: 6000,
                    imagesAsBackgrounds: true
                };
                AutomatitCarousel(options);
            } else {
                const options = {
                    element: document.getElementById('facility_carousel'),
                    speed: 600,
                    images: window.small_slide_images,
                    imagesAsBackgrounds: true,
                    intervalTiming: 0,
                    useChevrons: false
                };
                AutomatitCarousel(options);
            }
        }
    }

    return my;
})();

export const facilityGallery = (function () {
    let my = {};

    function _showGallery() {
        document.getElementById('facility_gallery_overlay').classList.add('active');
    }

    function _hideGallery(e) {
        if (e.target.id === "facility_gallery_overlay") {
            document.getElementById('facility_gallery_overlay').classList.remove('active');
        }
    }

    my.init = function () {
        if (document.getElementById('facility_gallery')) {

            document.getElementById('facility_gallery_toggle').addEventListener('click', _showGallery);
            document.getElementById('facility_gallery_overlay').addEventListener('click', _hideGallery);

            if (window.slide_images.length > 1) {
                const options = {
                    element: document.getElementById('facility_gallery'),
                    speed: 600,
                    images: window.slide_images,
                    intervalTiming: 0,
                    imagesAsBackgrounds: true
                };
                AutomatitCarousel(options);
            } else {
                const options = {
                    element: document.getElementById('facility_gallery'),
                    speed: 600,
                    images: window.slide_images,
                    imagesAsBackgrounds: true,
                    intervalTiming: 0,
                    useChevrons: false
                };
                AutomatitCarousel(options);
            }
        }
    }

    return my;
})();

export const facilityMap = (function () {
    let my = {},
        map = document.getElementById('facility_map'),
        mapBtn = document.querySelector('#facility_top_buttons .map'),
        infoBtn = document.querySelector('#facility_top_buttons .info'),
        mapLoaded = false;

    function _initMap() {
        const mapHelper = getMapsHelper();

        mapHelper.ready()
            .then(() => {
                const theMap = mapHelper.createMap({
                    element: map,
                    locationElementSelector: '#facility_map',
                    useRichmarker: true,
                    markerReducer: el => {
                        return {
                            lat: el.getAttribute('lat'),
                            lng: el.getAttribute('lng'),
                            content: `
                        <span class="map_pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                        </span>
                        `
                        };
                    }
                });

                google.maps.event.addListenerOnce(theMap, 'idle', () => {
                    theMap.setZoom(15);
                });
            });
    }

    function _showMap() {
        if(!mapLoaded){
            _initMap();
            mapLoaded = true;
        }
        map.classList.add('active');
        mapBtn.classList.add('active');
        infoBtn.classList.remove('active');
    }

    function _hideMap() {
        map.classList.remove('active');
        mapBtn.classList.remove('active');
        infoBtn.classList.add('active');
    }

    function _assignListeners() {
        mapBtn.addEventListener('click', _showMap);
        infoBtn.addEventListener('click', _hideMap);
    }

    my.init = function () {
        if (map) {
            _assignListeners();
        }
    }

    return my;
})();

export const ratesShowMore = (function () {
    let my = {};

    function _showUnavailable(e) {
        e.currentTarget.style.display = "none";
        [].forEach.call(document.querySelectorAll('#rates_table .unavailable'), (el) => {
            el.classList.remove('unavailable');
        });

        rateFilters.filterUnits();
    }

    my.init = function () {
        if (document.getElementById('rates_show_more_toggle')) {
            document.getElementById('rates_show_more_toggle').addEventListener('click', _showUnavailable);
        }
    }

    return my;
})();

export const rateFilters = (function () {
    let my = {};

    my.filterUnits = function(){
        let size = document.querySelector('#rates_filters .active').dataset.size;

        let i = 0;

        [].forEach.call(document.querySelectorAll('#rates_table .unit_row'), (row) => {
            if ((size == 'all' || row.dataset.size == size) && !row.classList.contains('unavailable')) {
                row.style.display = "flex";
                i++;
            } else {
                row.style.display = "none";
            }
        });

        if (i > 0) {
            document.getElementById("no_units").style.display = "none";
        } else {
            document.getElementById("no_units").style.display = "block";
        }
    }

    function _filterUnits(e) {
        document.querySelector('#rates_filters .active').classList.remove('active');
        e.currentTarget.classList.add('active');

        my.filterUnits();
    }

    my.init = function () {
        if (document.getElementById('rates_filters')) {
            [].forEach.call(document.querySelectorAll('#rates_filters .filter'), (el) => {
                el.addEventListener('click', _filterUnits);
            });
        }
    }

    return my;
})();

export const sidebar = (function () {
    let my = {};

    function _toggleContent(e) {
        e.currentTarget.parentElement.classList.toggle('closed');
    }

    my.init = function () {
        [].forEach.call(document.querySelectorAll('.facility_sidebar_toggle h3'), (el) => {
            el.addEventListener('click', _toggleContent);
        });

        if(window.innerWidth <= 750){
            [].forEach.call(document.querySelectorAll('.facility_sidebar_toggle h3'), (el) => {
                el.click();
            });
        }
    }

    return my;
})();

export const facilityContentToggle = (function () {
    let my = {};

    function _openContent() {
        document.getElementById('facility_top_toggle').classList.toggle('active');
        document.getElementById('facility_top_content').classList.toggle('active');
    }

    my.init = function () {
        if (document.getElementById('facility_top_toggle')) {
            document.getElementById('facility_top_toggle').addEventListener('click', _openContent);
        }
    }

    return my;
})();