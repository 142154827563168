export const supplies = (function(){
    let my = {},
    submitInProgress = false;

    function _quantityChangeListeners(){
        [].forEach.call(document.querySelectorAll('.quantity_input'), function(input){
            input.addEventListener('change', (e) => {
                let item = e.currentTarget;
                let quantity = item.value;
                let price = item.dataset.price;

                document.getElementById("item_" + item.dataset.item).value = quantity;
                document.querySelector("#cart_item_" + item.dataset.item + " .quantity_input").value = quantity;
                document.getElementById("item_" + item.dataset.item + "_total").value = (quantity * price).toFixed(2);
                document.getElementById("cart_item_" + item.dataset.item + "_total").value = (quantity * price).toFixed(2);
                
                if(quantity == 0){
                    document.querySelector("#cart_item_" + item.dataset.item).style.display = "none";
                }else{
                    document.querySelector("#cart_item_" + item.dataset.item).style.display = "flex";
                }

                _getTotal();
            });
        });
    }

    function _getTotal(){
        let total = 0;
        [].forEach.call(document.querySelectorAll("#supplies_cart .cart_item_total_price"), function(input){
            if(input.value){
                total += Number.parseFloat(input.value);
            }
        });
        document.querySelector("#supplies_total span").innerHTML = total.toFixed(2);
    }

    function _updateResponseMessage(errors){
        var response = '';
        console.log(errors);
        if(errors.length === 1){
            response = "The "+errors[0]+" field is required.";
        }else if(errors.length === 2){
            response = "The "+errors[0]+" and "+errors[1]+" fields are required.";
        }else{
            let fields = "";
            for(let x = 0; x < errors.length; x++){
                fields += errors[x];
                if(x < errors.length - 1){
                    fields += ', ';
                }
                if(x === errors.length - 2){
                    fields += 'and ';
                }
            }
            response = "The "+fields+" fields are required.";
        }
        document.querySelector('.response_message').innerHTML = "<span class='error'>"+response.replaceAll('_', ' ')+"</span>";
    };

    const _listenToSupplyForm = ({
        elementSelector = '#packing_supplies_form',
        required = ['name', 'address', 'city', 'state', 'zip', 'home_phone', 'email', 'facility']
    } = {}) => {
        
        document.querySelector(elementSelector).addEventListener('submit', function(e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            if(document.getElementById('hp').value !== '')return;
            const formEl = document.querySelector(elementSelector);
            Array.prototype.forEach.call(formEl.querySelectorAll('.error'), function(error) {
                error.classList.remove('error');
            });
            formEl.querySelector('.response_message').innerHTML = '';
        
            if(submitInProgress) {
                return;
            }
        
            let errorsFound = new Array();
            required.forEach(function(item){
                if(document.getElementById(item+'_input').value === ''){
                    document.getElementById(item+'_input').classList.add('error');
                    errorsFound.push(item);
                }
            });
            let quantity = 0;
            [].forEach.call(document.querySelectorAll('.quantity_input'), function(el){
                if(el.value !== ''){
                    quantity += parseInt(el.value);
                }
            });
            if(quantity === 0){
                document.querySelector('.response_message').innerHTML = "<span class='error'>Please select some items above before submitting.</span>";
                return;
            }
            if(!submitInProgress && errorsFound.length === 0){
                submitInProgress = true;
                
                let request = new XMLHttpRequest(),
                    method = 'POST',
                    url = formEl.getAttribute('action'),
                    data = new FormData(formEl);
        
                request.onreadystatechange = function() {
                    if(request.readyState === 4 && request.status === 200) {
                        let response = JSON.parse(request.responseText);
                        if(response.ret_det.success){
                            let input = document.getElementById('supply_form_submit');
                            input.parentElement.removeChild(input);
                            formEl.querySelector('.response_message').innerHTML = "<span class='success'>"+response.ret_det.message+"</span>";
                        }else{
                            if(typeof response.data.error_fields !== 'undefined'){
                                let errors = [];
                                for(let x = 0; x < response.data.error_fields.length; x++){
                                    errors.push(response.data.error_fields[x].field_name);
                                }
                                _updateResponseMessage(errors);
                            }else{
                                formEl.querySelector('.response_message').innerHTML = "<span class='error'>"+response.ret_det.message+"</span>";
                            }
                        }
                        submitInProgress = false;
                    }
                };
                request.open(method, url, true);
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                request.send(data);
            }
            else if(errorsFound.length > 0){
                _updateResponseMessage(errorsFound);
            }
            else{
                formEl.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
            }
        });
    };

    my.init = function(){
        if(document.getElementById('supplies_listing')){
            _quantityChangeListeners();
            _listenToSupplyForm();
        }
    }

    return my;
})();