import {
    AutomatitCarousel
} from './automatit_carousel.m';

export const review_caro = (function () {
    let my = {};

    my.init = function () {
        if (document.getElementById('reviews_carousel')) {

            let chevrons = false;
            let interval = 0;
            
            if(document.querySelectorAll('.review_slide').length > 1){
                chevrons = true;
                interval = 5000;
            }

            window.onload = function(){
                AutomatitCarousel({
                    element: document.getElementById('reviews_carousel'),
                    useChevrons: chevrons,
                    intervalTiming: interval
                });
                const resizeTimer = setInterval(function(){
                    if(document.querySelector('#reviews_carousel .caro_inner')){
                        let minHeight = 0;
                        [].forEach.call(document.querySelectorAll('#reviews_carousel .review_slide'), function(slide){
                            let tempDisplay = slide.style.display;
                            slide.style.display = 'block';
                            slide.style.marginTop = '-'+(slide.offsetHeight/2)+'px'
                            if(slide.offsetHeight > minHeight){
                                minHeight = slide.offsetHeight;
                            }
                            slide.style.display = tempDisplay;
                        });
                        document.querySelector('#reviews_carousel .caro_inner').style.height = minHeight + 'px';
                        clearInterval(resizeTimer);
                    }
                }, 500);
            }
        }
    };

    return my;
})();

export const feedback = (function(){
    let my = {};

    my.init = function(){
        if(document.getElementById('write_review')){
            feedbackForm({
                element:document.querySelector('#write_review'),
                srcPath:"/static/ai_rev_ff/",
                reviewsCompanyId: window.reviewsCompanyId,
                reviewsFacilityId: window.reviewsFacilityId
            });
        }
    }

    return my;
})();